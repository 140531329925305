.navBar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-background);
  height: 100vh;
  width: var(--navbar-width);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 25px;
  padding-bottom: 25px;
  border-right: solid 1px var(--color-border);
  background-color: var(--color-main-blue);
  gap: 15px;

  .navigateSection {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    overflow-y: auto;
    color: #ffffff;

    .subheader {
      background-color: var(--color-main-blue);
      color: var(--color-accent-orange);
      padding: 0 30px;
    }
  }

  .optionContainer {
    display: flex;
    flex-direction: row;
    .button__logout {
      width: 180px;
      box-shadow: 1px 1px 1px 1px rgba(128, 128, 128, 0.1);
      background-color: #ffffff;
    }
  }
}
