@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@value breakPointDesktop: 1024px;
@value breakPointTablet: 768px - 1023px;
@value breakPointMobile: 767px;
@value primaryButton: #277DA1;
@value accentButton:#577590;
@value mainOrange:#edaa58;
@value accentOrange:#E7B271;
@value mainBlue:#294f84;
@value accentBlue:#9FC6C0;
@value errorRed:#ff0033;
@value mainBackground: #FFFBF4;

@value roomMenuWidth: 150px;

/* LIGHT */
@value lightBackground:#f9f9f9;
@value lightPaper:#ffffff;
@value: lightBorder:rgba(0, 0, 0, 0.12);
@value lightText: #000000;

/* DARK */
@value darkBackground:#18181a;
@value darkPaper:#27242b;
@value darkBorder: rgba(255, 255, 255, 0.12);
@value darkText: #ffffff;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  // transition: background-color 150ms ease-in-out 100ms !important;
}

*::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
  background: var(--color-background); /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: #ccc; /* color of the scroll thumb */
  border-radius: 25px; /* roundness of the scroll thumb */
  /* border: 3px solid orange; creates padding around scroll thumb */
}

body {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  overflow: hidden;
}

:root {
  --break-point-desktop: breakPointDesktop;
  --break-point-mobile: breakPointMobile;
  --nav-bar-height: 50px;
  --main-content-height: calc(100vh - var(--nav-bar-height));
  --navbar-width: 250px;
  --main-content-width: calc(100vw - var(--navbar-width));
  --room-menu-width: roomMenuWidth;

  --color-button-primary: primaryButton;
  --color-main-orange: mainOrange;
  --color-accent-orange: accentOrange;
  --color-main-blue: mainBlue;
  --color-accent-blue: accentBlue;
  --color-main-background: mainBackground;
  --color-error-red: errorRed;

  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

[data-theme='light'] {
  --color-background: lightBackground;
  --color-paper: #ffffff;
  --color-border: lightBorder;
  --color-extra-foreground: #f9f9f9;
  --color-text: lightText;
  --color-text-contrast: darkText;
}

[data-theme='dark'] {
  --color-background: darkBackground;
  --color-paper: #27242b;
  --color-border: darkBorder;
  --color-extra-foreground: #3d3a41;
  --color-text: darkText;
  --color-text-contrast: lightText;
}
