.root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  cursor: pointer;
  transition-property: border;
  transition-duration: 100ms;
  color: var(--color-text);
  margin: 5px 0;
  border-radius: 5px;

  &:hover {
    background-color: var(--color-accent-orange);
    border-right: solid 4px var(--color-text);
    transition-property: border;
    transition-duration: 100ms;
    color: var(--color-main-blue);
  }
}

.currentPath {
  background-color: var(--color-accent-blue);
  color: var(--color-main-blue);
  transition-property: background-color;
  transition-duration: 100ms;
}
