.drawer {
  width: 300px;
  .header {
    display: flex;
    justify-content: flex-end;
  }
  .content {
    display: flex;
    // justify-content: center;
    flex-grow: 1;
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    p {
      font-size: 0.75em;
    }
  }
}
