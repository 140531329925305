.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 170px;
  width: 100%;
  user-select: none;

  .logoVector {
    margin-right: 5px;
    text-shadow: 3px 1px 7px rgba(150, 150, 150, 0.71);
  }

  .logoFont {
    font-weight: bold;
    font-size: 35px;
    color: #fff;
    text-shadow: 3px 1px 7px rgba(150, 150, 150, 0.71);
  }

  .logo {
    width: 30%;
    object-fit: contain;
  }
}
