.root {
	display: flex;
	flex-direction: row;
}
.navBar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	height: var(--nav-bar-height);
	padding-left: 10px;
	padding-right: 10px;
}

.content {
	height: 100vh;
	width: var(--main-content-width);
	background-color: var(--color-background);
}
